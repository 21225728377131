<template>
  <div>
    <!-- 上部分 -->
    <div class="top">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>农废回收量</p>
      </div>

      <div class="echart">
        <echarts :option="option"></echarts>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="botom">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>农废监控</p>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from '@/components/echarts/index.vue';
import { getPieData } from '@/api/nfhs/index.js';
export default {
  name: 'Left',
  components: {
    // [Button.name]: Button // 此处是引入组件名字的方法
    echarts
  },
  data () {
    return {
      option: {}
    };
  },
  created () {},
  mounted () {

    this.getData();
  },
  methods: {

    async getData () {
      const { data } = await getPieData({ status: 2 });

      const chartData = data.map(item => {
        return {
          name: item.agriWasteType,
          value: item.weight
        };
      });
      const color = [
        '#8543E0',
        '#1890FF',
        '#FACC14',
        '#2FC25B',
        '#fad337',
        '#f2637b',
        '#ff8e49',
        '#37cbcb'
      ];
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      this.option = {
        background: 'transparent',
        title: {
          show: true,
          text: '农废回收量',
          subtext: `${ sum }吨`,
          x: '50%',
          y: '20%',
          textStyle: {
            color: '#A8C3E6',
            fontSize: 16,
            fontWeight: '400'
          },
          subtextStyle: {
            color: '#DBE7F6',
            fontSize: 24,
            fontWeight: '400'
          },
          textAlign: 'center'
        },
        legend: {
          orient: 'vertical',
          // icon: "diamond",
          icon: 'circle',
          textStyle: {
            color: '#373737',
            fontSize: '.15rem',
            rich: {
              name: {
                color: '975fe4',
                fontSize: 12,
                width: 60,
                fontWeight: 400,
                padding: [ 0, 0, 0, 2 ]
              },
              symbol: {
                fontSize: 12,
                fontWeight: 400,
                padding: [ 0, 0, 0, 8 ],
                color: '975fe4'
              },
              percent: {
                fontSize: 12,
                fontWeight: 400,
                width: 30,
                padding: [ 0, 0, 0, 4 ],
                color: '975fe4'
              },
              value: {
                color: '975fe4',
                fontSize: 12,
                fontWeight: 400,
                padding: [ 0, 0, 0, 24 ]
              }
            }
          },
          top: '70%',
          right: '0%',
          itemGap: 14,
          itemHeight: 24,
          itemWidth: 20,
          data: chartData.name,
          formatter: function (name) {
            let obj = {};
            if (name) {
              obj = chartData.find(item => item.name === name);
            }
            return `{name|${ name }}{symbol| |}{percent|${
              obj.value && ((obj.value / sum) * 100).toFixed(0)
            }%}{value|${ obj.value || 0 }吨}`;
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            roundCap: true,
            radius: [ '40%', '55%' ],
            center: [ '50%', '30%' ],
            startAngle: 180, //起始角度
            data: chartData,
            itemStyle: {
              borderWidth: 10,
              color: function (params) {
                return color[params.dataIndex];
              }
            },
            labelLine: {
              length: 8,
              length2: 16,
              lineStyle: {
                width: 1
              }
            },
            label: {
              show: false,
              fontFamily: 'ysbth',
              position: 'outside',
              padding: [ 0, -4, 0, -4 ],
              formatter (params) {
                if (params.name === '') {
                  return '';
                }
                return `${ params.percent.toFixed(0) }%`;
              },
              color: '#fff',
              fontSize: '.175rem',
              lineHeight: 10
            }
          }
        ]
      };
    }
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.top {
  width: companyW(444vw);
  height: companyH(465vh);
  background-color: rgba($color: #000000, $alpha: 0.7);

  .echart {
    margin: 0 auto;
    margin-top: companyH(20vh);
    width: companyW(400vw);
    height: companyH(350vh);
  }
}
.meter {
  display: flex;
  background: linear-gradient(90deg, #ab7c0a 0%, rgba(55, 20, 113, 0) 100%);
  padding: companyW(7vw);
  img {
    margin: 0 companyW(10vw);
  }
  p {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: companyW(20vw);
    color: #ffffff;
  }
}
.botom {
  width: companyW(444vw);
  height: companyH(465vh);
  background-color: rgba($color: #000000, $alpha: 0.7);

  margin-top: companyH(22vh);
  position: relative;
}

.kindType {
  color: #2cb252;

  display: flex;
  align-items: center;
  p:nth-child(1) {
    margin: 0 companyW(200vw) 0 companyW(50vw);
  }
  p:nth-child(2) {
    margin-right: companyW(20vw);
    color: #91a4ad;
    font-size: companyW(16vw);
  }
}
.detailed {
  padding: 0 companyW(15vw) 0 companyW(48vw);
  display: flex;
  flex-wrap: wrap;
  margin-top: companyH(25vh);

  .individual {
    color: #fff;
    margin-bottom: companyH(15vh);
    margin-right: companyW(33vw);
    p:nth-child(1) {
      color: #82929b;
      margin-bottom: companyH(8vh);
      font-size: companyW(14vw);
    }
  }
}
.arrow {
  width: companyW(32vw);
  height: companyH(32vh);
}
.left {
  position: absolute;
  left: companyW(10vw);
  bottom: companyH(58vh);
}
.right {
  position: absolute;
  right: companyW(10vw);
  bottom: companyH(58vh);
}
</style>
