<template>
  <div>
    <!-- 上部分 -->
    <div class="top">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>每日回收量</p>
      </div>

      <div class="echart">
        <echart :option="option"></echart>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="botom">
      <div class="meter">
        <img src="@/assets/distributionImg/dots.png" alt="" />
        <p>实时农废量</p>
      </div>

      <div class="dingdan">
        <el-table
          stripe
          v-loading="tabLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="TanleContent"
        >
          <el-table-column
            prop="userName"
            label="回收对象"
            align="center"
            width="100"
          >
          </el-table-column>

          <el-table-column prop="num" label="种类及数量" align="center">
            <template v-slot="{ row }">
              <span>
                {{ row.agriWasteType }}-{{ row.weight }}{{ row.unit }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="orderTime" label="下单时间" align="center">

            <template v-slot="{ row }">
              <span>
                {{ row.orderTime.substr(2) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import echart from '@/components/echarts/index.vue';
import echarts from 'echarts';
import { getLineData, selectAll } from '@/api/nfhs/index.js';
export default {
  name: 'Left',
  components: {
    // [Button.name]: Button // 此处是引入组件名字的方法
    echart
  },
  data () {
    return {
      option: {},
      TanleContent: [
        { ck: '出库', yj: '孙晓华', num: '20', time: '2-20 16:00' }
      ],
      tabLoading: false
    };
  },
  created () {
    this.getselectAll();
  },
  mounted () {
    this.getData();
  },
  methods: {
    async getselectAll () {
      const { data } = await selectAll({ pageNum: 1, pageSize: 50, status: 2 });
      this.TanleContent = data.records;
      console.log(data);
    },
    async getData () {
      const { data } = await getLineData({ status: 2 });

      const riqi = data.map(item => {
        return item.orderDay;
      });
      const shuliang = data.map(item => {
        return item.totalWeight;
      });
      this.option = {
        background: 'transparent',
        title: {
          text: '每日回收数量（吨）',
          top: 10,
          left: 15,
          textStyle: {
            color: '#9eaaba',
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: 60,
          containLabel: true
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          data: riqi,
          axisLabel: {
            //坐标轴字体颜色
            textStyle: {
              color: '#9eaaba'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#e5e5e5'
            }
          },
          axisTick: {
            //y轴刻度线
            show: false
          },
          splitLine: {
            //网格
            show: false
          },
          boundaryGap: true
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            //坐标轴字体颜色
            textStyle: {
              color: '#9eaaba'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            //y轴刻度线
            show: false
          },
          splitLine: {
            //网格
            show: true,
            lineStyle: {
              color: '#dadde4',
              type: 'dashed'
            }
          }
        },

        series: [
          {
            name: '回收量',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
              color: 'rgb(234, 128, 125)',
              borderColor: '#fff',
              borderWidth: 1
            },
            lineStyle: {
              normal: {
                color: 'rgb(234, 128, 125)',
                shadowColor: 'rgba(234, 128, 125, .4)',
                shadowBlur: 8,
                shadowOffsetY: 10,
                shadowOffsetX: 0
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(234, 128, 125, .4)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(234, 128, 125, 0)'
                  }
                ])
              }
            },
            data: shuliang
          }
        ]
      };
    }
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.top {
  width: companyW(444vw);
  height: companyH(465vh);
  background-color: rgba($color: #000000, $alpha: 0.7);

  .echart {
    margin: 0 auto;
    margin-top: companyH(20vh);
    width: companyW(420vw);
    height: companyH(380vh);
  }
}
.meter {
  display: flex;
  background: linear-gradient(90deg, #ab7c0a 0%, rgba(55, 20, 113, 0) 100%);
  padding: companyW(7vw);
  img {
    margin: 0 companyW(10vw);
  }
  p {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: companyW(20vw);
    color: #ffffff;
  }
}
.botom {
  width: companyW(444vw);
  height: companyH(465vh);
  background-color: rgba($color: #000000, $alpha: 0.7);

  margin-top: companyH(22vh);
  position: relative;
}

.kindType {
  color: #2cb252;

  display: flex;
  align-items: center;
  p:nth-child(1) {
    margin: 0 companyW(200vw) 0 companyW(50vw);
  }
  p:nth-child(2) {
    margin-right: companyW(20vw);
    color: #91a4ad;
    font-size: companyW(16vw);
  }
}
.detailed {
  padding: 0 companyW(15vw) 0 companyW(48vw);
  display: flex;
  flex-wrap: wrap;
  margin-top: companyH(25vh);

  .individual {
    color: #fff;
    margin-bottom: companyH(15vh);
    margin-right: companyW(33vw);
    p:nth-child(1) {
      color: #82929b;
      margin-bottom: companyH(8vh);
      font-size: companyW(14vw);
    }
  }
}
.arrow {
  width: companyW(32vw);
  height: companyH(32vh);
}
.left {
  position: absolute;
  left: companyW(10vw);
  bottom: companyH(58vh);
}
.right {
  position: absolute;
  right: companyW(10vw);
  bottom: companyH(58vh);
}
.dingdan {
  margin-top: companyH(20vh);
}

::v-deep .el-table th {
  background: #000000;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(18vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before{
  background: #000000;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td{
  background: #333;

}
</style>
